import { STOREFRONT_TITLE_EXPERIMENT_MAP } from '@constants/experiments';
import type { ABExperimentGroups } from '@constants/experiments/types';

export const getRenameStorefrontTitleExperimentGroup = (
	displayId: string,
): ABExperimentGroups => {
	const lastDigitDisplayId = displayId.charAt(displayId.length - 1);
	return lastDigitDisplayId
		? (STOREFRONT_TITLE_EXPERIMENT_MAP[lastDigitDisplayId] ?? 'control')
		: 'control';
};

export const getStorefrontTitleExperiment = (
	vendorName: string,
	vendorCategory: string,
	displayId: string,
): string => {
	const experimentGroup = getRenameStorefrontTitleExperimentGroup(displayId);

	switch (experimentGroup) {
		case 'A':
			return `${vendorName} | ${vendorCategory} Price`;
		case 'B':
			return `${vendorName} + Updated Prices`;
		default:
			return `${vendorName} | ${vendorCategory}`;
	}
};
