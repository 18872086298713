import { useStorefrontEstimatedPricingExperimentAssignment } from '@hooks/use-storefront-estimated-pricing-experiment';
import { reportServerSideExperiment } from '@redux/experiments';
import { storefrontSearchSelector } from '@redux/experiments/selectors/storefront-search';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { experiments } from '@settings';
import { Logger } from '@utils/logger/logger';
import { useCallback, useEffect } from 'react';
import type { Id } from 'types/experiments';

export const useStorefrontExperiments = () => {
	const dispatch = useAppDispatch();
	const estimatedPricingAssignment =
		useStorefrontEstimatedPricingExperimentAssignment();

	const storefrontSearchAssignment = useAppSelector(storefrontSearchSelector);

	const reportExperiment = useCallback(
		(experimentId: Id) => {
			dispatch(reportServerSideExperiment(experimentId));
		},
		[dispatch],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: This effect should only run once
	useEffect(() => {
		if (estimatedPricingAssignment) {
			Logger.log({
				log_message: `Estimated Pricing Assignment: ${estimatedPricingAssignment}`,
			});
			reportExperiment(experiments.estimatedPricing);
		}

		if (storefrontSearchAssignment) {
			Logger.log({
				log_message: `Storefront Search Assignment: ${storefrontSearchAssignment}`,
			});
			reportExperiment(experiments.storefrontSearch);
		}
	}, []);

	return {
		estimatedPricingAssignment,
	};
};
