import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { Body2, Subhead } from '@xo-union/tk-ui-typography';
import React from 'react';
import { Sparkle } from './Sparkle';
import Styles from './styles.scss';
interface ReviewsAISummaryProps {
	reviewSummary: string;
}

const ReviewAISummary: React.FC<ReviewsAISummaryProps> = ({
	reviewSummary,
}) => {
	const impressionRef = useTrackStorefrontImpression('Review AI Summary');
	return (
		<div className={Styles.reviewAISummary} data-testid="review-ai-summary">
			<div className={Styles.reviewAISummaryTitle}>
				<Sparkle monochrome="black" size={'24'} />
				<Subhead ref={impressionRef} as="h2" bold>
					Review Summary
				</Subhead>
			</div>
			<Body2 className={Styles.reviewAISummarySubtitle}>
				This AI-generated summary is a snapshot of this vendor's ratings and
				feedback.
			</Body2>
			{reviewSummary}
		</div>
	);
};

export default ReviewAISummary;
