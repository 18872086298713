import { useViewReviews } from '@components/reviews/hooks/use-view-storefront-reviews';
import * as ExperimentActions from '@redux/experiments';
import React, { type FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import type { State } from 'types/redux';
import type { Decorated, Raw } from 'types/vendor';
import VendorAnalytics from '../../../../decorators/vendorAnalytics';
import type { NavItemProps } from '../nav/types';
import Reviews from './components/reviews/Reviews';
import Constants from './constants';
import { useFetchReviews } from './hooks/useFetchReviews';
import { useUpdateAmountToRender } from './hooks/useUpdateAmountToRender';
import { useViewMoreOrFetchReviews } from './hooks/useViewMoreOrFetchReviews';

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

type OwnProps = NavItemProps & {};

type Props = StateProps & DispatchProps & OwnProps;

const ReviewsContainer: FC<Props> = ({
	member,
	vendor,
	vendorRaw,
	reportServerSideExperiment,
}) => {
	const {
		collection,
		isFetching,
		totalVendorReviewsCount,
		totalCount,
		buckets,
		isFetchingMore,
		rating,
	} = useViewReviews();
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);
	const reviewsCount = useMemo(() => collection.length, [collection]);
	const [amountToRender, setAmountToRender] = useState(Constants.INCREMENT_BY);
	const updateAmountToRender = useUpdateAmountToRender(
		setAmountToRender,
		isFetching,
	);
	const fetchReviews = useFetchReviews(
		reviewsCount,
		vendor.id,
		vendor.isPaid,
		updateAmountToRender,
	);
	const viewMoreOrFetchReviews = useViewMoreOrFetchReviews(
		reviewsCount,
		amountToRender,
		vendorAnalytics,
		fetchReviews,
		updateAmountToRender,
	);

	const reviewSummaryGenerated =
		vendorRaw.ai?.reviewSummaryGenerated?.summaryText;

	return (
		<Reviews
			buckets={buckets}
			reviews={collection}
			amountToRender={amountToRender}
			reviewsCount={totalCount}
			viewMoreOrFetchReviews={viewMoreOrFetchReviews}
			isFetching={isFetching}
			isFetchingMore={isFetchingMore}
			totalVendorReviewsCount={totalVendorReviewsCount}
			vendorAnalytics={vendorAnalytics}
			vendorId={vendor.id}
			member={member}
			rating={rating}
			reviewSummaryGenerated={reviewSummaryGenerated}
		/>
	);
};

export function mapStateToProps(state: State) {
	return {
		member: state.membership.member || {},
		vendor: state.vendor.vendor as Decorated,
		vendorRaw: state.vendor.vendorRaw as Raw,
		isMobile: state.viewport.isMobile,
	};
}

export const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const ReviewsContainerWithRedux = connect<StateProps, object, OwnProps>(
	mapStateToProps,
	mapDispatchToProps,
)(ReviewsContainer);

const ReviewsWithJotai: FC<NavItemProps> = () => <ReviewsContainerWithRedux />;

export default ReviewsWithJotai;
