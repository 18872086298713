import ReviewsModalVerTwo from '@components/reviews/components/reviews_modal_ver_two';
import { SearchSortFilter } from '@components/reviews/components/search-sort-filter';
import { ReviewPhotosFilmstrip } from '@containers/review-photos/ReviewPhotosFilmstrip';
import type { ReviewBuckets, Review as ReviewType } from '@typings/reviews';
import Spinner from '@xo-union/tk-component-spinner';
import { H4 } from '@xo-union/tk-ui-typography';
import type VendorAnalytics from 'apps/marketplace-web/client/decorators/vendorAnalytics';
import classNames from 'classnames';
import React, { type FC, useState } from 'react';
import Review from '../../review/Review';
import { ReviewPromptBox } from '../../review_prompt_box';
import ReviewsSummary from '../../reviews_summary';
import VerifiedReviewsBox from '../../verified_reviews_box';
import Styles from '../Reviews.scss';
import { ViewMoreReviews } from '../components/view_more_reviews';
import { GoogleReviewsToggleSelector } from './components/google_reviews_toggle_selector/GoogleReviewsToggleSelector';
import { GoogleReviewsWrapper } from './components/google_reviews_wrapper/GoogleReviewsWrapper';

export interface OwnProps {
	amountToRender: number;
	buckets: ReviewBuckets;
	isFetching: boolean;
	isFetchingMore: boolean;
	rating: number;
	reviews: ReviewType[];
	reviewsCount: number;
	totalVendorReviewsCount: number;
	vendorAnalytics?: VendorAnalytics;
	viewMoreOrFetchReviews: () => void;
	className?: string;
	reviewSummaryGenerated: string | null;
	pageType: string;
	openModal: () => void;
	closeModal: () => void;
	modalIsOpen: boolean;
	shouldShowScannability: boolean;
	shouldShowText: boolean;
	secondaryCTAIndex: number;
	googleReviewsData: {
		rating: number;
		totalReviewCount: number;
		externalUrl: string;
		reviews: {
			id: string;
			authorName: string;
			publicProfilePicture: string;
			publishedAt: string;
			rating: number;
			reviewText: string;
		}[];
	};
}

type Props = OwnProps;

export const ReviewsWithGoogleReviews: FC<Props> = ({
	amountToRender,
	buckets,
	isFetching,
	isFetchingMore,
	rating,
	reviews,
	reviewsCount,
	totalVendorReviewsCount,
	vendorAnalytics,
	viewMoreOrFetchReviews,
	className,
	reviewSummaryGenerated,
	pageType,
	openModal,
	closeModal,
	modalIsOpen,
	shouldShowScannability,
	shouldShowText,
	secondaryCTAIndex,
	googleReviewsData,
}) => {
	const [showTKReviews, setShowTKReviews] = useState(
		totalVendorReviewsCount > 0,
	);

	return (
		<div className={className} data-testid="reviews-with-google-reviews">
			{modalIsOpen && (
				<ReviewsModalVerTwo
					isModalOpen={modalIsOpen}
					closeModal={closeModal}
					pageType={pageType}
				/>
			)}
			<div className={Styles.reviewsContainer}>
				<div className={Styles.headerContainer}>
					<H4 id="reviews">Reviews</H4>
					{!!totalVendorReviewsCount && !shouldShowScannability && (
						<VerifiedReviewsBox />
					)}
				</div>
				<div className={Styles.reviewsContent}>
					{totalVendorReviewsCount > 0 && (
						<ReviewsSummary
							rating={rating}
							buckets={buckets}
							reviewsCount={totalVendorReviewsCount}
							openModal={openModal}
							vendorAnalytics={vendorAnalytics}
							showVerifiedReviewsMessage={!!totalVendorReviewsCount}
							reviewSummaryGenerated={reviewSummaryGenerated}
						/>
					)}
					<ReviewPhotosFilmstrip />
					<GoogleReviewsToggleSelector
						showTKReviews={showTKReviews}
						setShowTKReviews={setShowTKReviews}
						rating={rating}
						reviewsCount={totalVendorReviewsCount}
						googleRating={googleReviewsData.rating}
						googleReviewsCount={googleReviewsData.totalReviewCount}
					/>
					{showTKReviews && (
						<div
							className={classNames(Styles.reviewsWrapper, {
								[Styles.filteredReviews]: shouldShowText,
							})}
						>
							{totalVendorReviewsCount > 0 ? (
								<div data-testid="review-search-sort-filter">
									<SearchSortFilter />
								</div>
							) : (
								<ReviewPromptBox
									title="No reviews yet"
									message="Be the first couple to share your thoughts!"
									handleClick={openModal}
								/>
							)}
							<div>
								{isFetching ? (
									<div className={Styles.ssfLoaderContainer}>
										<div className={Styles.ssfLoader}>
											<Spinner />
										</div>
									</div>
								) : (
									<>
										<div
											data-testid="review-items"
											className={Styles.reviewItems}
										>
											{reviews.map((review, index) => (
												<Review
													key={`review-${review.id}`}
													comments={review.comments}
													content={review.content}
													createdDate={review.createdDate}
													rating={review.rating}
													reviewer={{
														firstName: review.reviewer.firstName,
														lastName: review.reviewer.lastName,
													}}
													vendorAnalytics={vendorAnalytics}
													hidden={index >= amountToRender}
													showSecondaryCTA={index === secondaryCTAIndex}
													gallery={review.gallery}
													isTestimonial={review.isTestimonial}
													border={index > 0}
												/>
											))}
										</div>
										<ViewMoreReviews
											isFetchingMore={isFetchingMore}
											amountRendered={amountToRender}
											totalReviewsCount={reviewsCount}
											viewMoreOrFetchReviews={viewMoreOrFetchReviews}
										/>
									</>
								)}
							</div>
						</div>
					)}
					{!showTKReviews && (
						<>
							{!totalVendorReviewsCount && (
								<ReviewPromptBox
									title="Have you booked this vendor?"
									message="Be the first couple to share your thoughts!"
									handleClick={openModal}
								/>
							)}
							<GoogleReviewsWrapper
								googleReviews={googleReviewsData.reviews}
								googleReviewsLink={googleReviewsData.externalUrl}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
